
<template>
<div>
  <div class="columns">
    <div class="column">
      <card-component
        title="Support"
        icon="info"
      >
        <p>Support for ASTERICS can be obtained at <a href="mailto:asterics-tlse@inrae.fr">asterics-tlse@inrae.fr</a>.
         If you want to report a bug or make a suggestion please note the links below.</p>
      </card-component>

      <card-component
        title="Beta version / Bug report"
        icon="warning"
      >
        <div class="content">
        <p>This is a beta version of ASTERICS, released for large scale testing purpose.
           Be aware that:
        </p>
          <ul>
            <li>it comes with <b>ABSOLUTELY NO WARRANTY</b>. In particular, we <b>do not guarantee
               the absence of loss of your data or analysis at this development stage</b>.
                You are responsible to save a copy of your data and of the results of
                 the analysis that you performed;</li>
            <li>this version is only partial and does not include all the features of the
               final tool. In particular, edition, cleaning and normalization of the data
               are still to be included as well as intuitive help to the user to perform
               and understand its analyses and export of an analysis report. </li>
          </ul>
          <p>
          You can use thoses links if you want <b>to
           <a href="https://github.com/astericsInrae/asterics-issues/issues/new?labels=bug&projects=&template=bug_report.md&title=%5BBUG%5D">report a bug</a>
           or <a href="https://github.com/astericsInrae/asterics-issues/issues/new?labels=Suggests&projects=&template=feature_request.md&title=%5BSUGGESTS%5D">make a suggestion</a></b>.
           You will have to connect to ForgeMIA with your LDAP login/password to post a
           report.
          </p>
          <p>
          Thank you for using ASTERICS!
          </p>
        </div>
      </card-component>
    </div>
  </div>
  <partners></partners>
</div>
</template>

<script>
import CardComponent from '@/components/ui/CardComponent'
import Partners from '@/components/ui/Partners'

export default {
  name: 'Contact',
  components: {
    CardComponent,
    Partners
  },
  data () {
    return {
    }
  }
}
</script>
